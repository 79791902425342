import * as pairing from "../utils/pairing";

const API_URL = process.env.REACT_APP_BASE_URL;

export const post = (path, body, headers) =>
  fetch(API_URL + path, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
  });

export const clearPushToken = async (data) => {
  const { userId, privateKey } = data;
  try {
    const nonce = pairing.generateNonce();
    const webAuth = true;
    const body = { nonce, userId, webAuth };

    const headers = pairing.generateSignatureHeaders(
      `${nonce}.unpair.${userId}`,
      privateKey
    );

    await post("/auth/unpair", body, headers);
    return;
  } catch (error) {
    console.log(error);
  }
};

export const getIntercomHashAndInit = async (data) => {
  const {
    pin,
    pairingData: { userId, privateKey },
  } = data;

  try {
    const nonce = pairing.generateNonce();
    const webAuth = true;

    const body = { pin, nonce, userId, webAuth };

    const headers = pairing.generateSignatureHeaders(
      `${nonce}.${pin}.${userId}`,
      privateKey
    );

    const response = await post("/auth/intercom", body, headers);
    const responseBody = await response.json();

    return {
      intercomHash: responseBody.intercomHash,
    };
  } catch (error) {
    console.log(error);
  }
};
