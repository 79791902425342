import generateKeyPair from "keypair";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import JSEncrypt from "jsencrypt";

export const generateDeviceKeyPair = () => {
  var keyPair = generateKeyPair();

  return {
    privateKey: keyPair.private,
    publicKey: keyPair.public,
  };
};

export const signMessage = (message, key) => {
  var sign = new JSEncrypt();
  sign.setPrivateKey(key);
  var signature = sign.sign(message, CryptoJS.SHA256, "sha256");
  return signature.replace("/s/g", "");
};

export const generateSignatureHeaders = (message, privateKey) => {
  // Add encrypted signature for device verification
  const signature = signMessage(message, privateKey);
  return { "X-Signature": signature };
};

export const generateNonce = () => uuidv4().toLowerCase();
