import logo from "../assets/logo.svg";

import Button from "./Button";

function Header(props) {
  const { isLoggedIn, logOut } = props;

  return (
    <>
      <img style={styles.logo} src={logo} alt="Donut Logo" />
      {isLoggedIn && (
        <Button
          onClick={logOut}
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          Log out
        </Button>
      )}
    </>
  );
}

const styles = {
  logo: {
    width: "auto",
    height: 40,
    marginTop: 20,
    marginLeft: 20,
    flex: 1,
  },
};

export default Header;
