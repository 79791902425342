import { useEffect } from "react";

function SignInScreen() {
  useEffect(() => {
    window.location.href = "https://donut.app";
  }, []);

  return null;
}

export default SignInScreen;