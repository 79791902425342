import loader from "../assets/loader.gif";

function Button(props) {
  const { style, onClick, children, isLoading } = props;
  return (
    <div
      className="button"
      onClick={isLoading ? () => {} : onClick}
      style={{ ...styles.root, ...style }}
    >
      {isLoading ? (
        <img alt="loading" src={loader} style={styles.loader} />
      ) : (
        children
      )}
    </div>
  );
}

const styles = {
  root: {
    cursor: "pointer",
    backgroundColor: "#1F1E1D",
    color: "white",
    borderRadius: 16,
    padding: 10,
    marginTop: 21,
    marginRight: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 250,
    minHeight: 25,
  },
  loader: {
    height: 20,
    width: 20,
  },
};

export default Button;
