import "./App.css";
import React, { useEffect } from "react";

import * as api from "./api";

import SignInScreen from "./screens/SignInScreen";
import HomeScreen from "./screens/HomeScreen";
import Header from "./components/Header";
import useIntercom from "./hooks/useIntercom";

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

function App() {
  const [pairingData, setPairingData] = React.useState(null);
  const [authToken, setAuthToken] = React.useState(null);
  const [reset, setReset] = React.useState(0);

  useIntercom();

  useEffect(() => {
    window.Intercom("shutdown");
    window.Intercom("boot", {
      app_id: INTERCOM_APP_ID,
    });
  }, [reset]);

  const logOut = () => {
    api.clearPushToken(pairingData);
    setAuthToken(null);
    setPairingData(null);
    localStorage.clear();
    setReset(reset + 1);
  };

  return (
    <>
      <Header isLoggedIn={authToken || pairingData} logOut={logOut} />
      <div className="App" style={styles.root}>
        {!authToken ? (
          <SignInScreen
            key={reset}
            pairingData={pairingData}
            setPairingData={setPairingData}
            setAuthToken={setAuthToken}
            logOut={logOut}
          />
        ) : (
          <HomeScreen authToken={authToken} logOut={logOut} />
        )}
      </div>
    </>
  );
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 50,
    height: "100vh - 20px",
    fontSize: 16,
    paddingLeft: 20,
    paddingRight: 20,
  },
};

export default App;
